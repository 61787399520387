import { useState, useMemo, useEffect } from "react";
import { StyleSheet, Pressable } from "react-native";
import { Feather } from "@expo/vector-icons";
import { loadAsync } from "expo-font";
import { View, Text } from "../components/Themed";
import { RootTabScreenProps } from "../types";
import {
  Solfege as SolfegeDisplayOption,
  DisplayOptions,
} from "../components/ear-trainer/NoteInfoButton";
import Notes, { C } from "../constants/Notes";
import Scales, { Major } from "../constants/Scales";
import PlayerOptions, { Option } from "../components/ear-trainer/PlayerOptions";
import SolfegeExercise from "../components/ear-trainer/SolfegeExercise";
import Scale from "../models/Scale";
import Cadence from "../models/Cadence";

export default function TabOneScreen({
  navigation,
}: RootTabScreenProps<"TabOne">) {
  const [key, setKey] = useState(C);
  const [scaleName, setScaleName] = useState(Major);
  const [displayOption, setDisplayOption] = useState(SolfegeDisplayOption);
  const [displayExercise, setTraining] = useState(false);
  const [scaleMemo] = useState(new Map<string, Scale>());
  const [cadenceMemo] = useState(new Map<string, Cadence>());
  const [ready, setReady] = useState(false);

  const scale = useMemo(() => {
    const memoKey = `${key}${scaleName}`;

    if (scaleMemo.has(memoKey)) {
      return scaleMemo.get(memoKey)!;
    }

    const newScale = new Scale(key, scaleName);
    scaleMemo.set(memoKey, newScale);
    return newScale;
  }, [key, scaleName]);

  const cadence = useMemo(() => {
    const memoKey = `${key}${scaleName}`;

    if (cadenceMemo.has(memoKey)) {
      return cadenceMemo.get(memoKey)!;
    }

    const newCadence = new Cadence(key, scaleName);
    cadenceMemo.set(memoKey, newCadence);
    return newCadence;
  }, [key, scaleName]);

  useEffect(() => {
    async function loadIcons() {
      try {
        loadAsync(Feather.font);
      } catch (e) {
        console.error(e);
      } finally {
        setReady(true);
      }
    }

    loadIcons();
  }, []);

  const options = [];
  options.push({ list: Notes, hook: [key, setKey] } as Option);
  options.push({ list: Scales, hook: [scaleName, setScaleName] } as Option);
  options.push({
    list: DisplayOptions,
    hook: [displayOption, setDisplayOption],
  } as Option);

  return (
    <View style={styles.container}>
      {displayExercise ? (
        <>
          <SolfegeExercise
            scale={scale}
            cadence={cadence}
            displayOption={displayOption}
          />
        </>
      ) : (
        <>
          <PlayerOptions options={options} />
        </>
      )}
      <Pressable
        disabled={!ready}
        onPress={() => {
          setTraining(!displayExercise);
        }}
      >
        <Text>{displayExercise ? "STOP" : "START"} EXERCISE</Text>
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
