import { createContext } from "react";

export type FeatureFlags = {
  enableKeyboardInput: boolean;
  enableSoundEffects: boolean;
};

export const disableAllOptionalFeatures = {
  enableKeyboardInput: false,
  enableSoundEffects: false,
};

export const enableAllOptionalFeatures = {
  enableKeyboardInput: true,
  enableSoundEffects: true,
};

export const FeatureContext = createContext(disableAllOptionalFeatures);
