import { useEffect } from "react";
import { SoundEffect } from "../models/SoundPlayer";
import { useIsFocused } from "@react-navigation/native";
import { useSoundPlayerContext } from "../components/Contexts/SoundPlayerContext";

/**
 * hook to restart audio context on focus and cancel all sound events on blur
 * @param soundEffect the desired sound effect
 */
export default function useSoundFocus(soundEffect: SoundEffect) {
  const player = useSoundPlayerContext();
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      player.restartAudioContext(soundEffect);
    } else {
      player.cancelAllEvents();
    }
    return () => player.cancelAllEvents();
  }, [isFocused, soundEffect]);
}
