import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { Text } from "./Themed";

export default function PlaySoundButton({
  label: baseText,
  duration,
  playing,
  onPress,
}: {
  label: string;
  duration: number;
  playing?: boolean;
  onPress: () => void;
}) {
  const playingText = baseText + " 🔊";
  const notPlayingText = baseText + " 🔈";

  const [buttonText, setButtonText] = useState(notPlayingText);

  const handlePress = () => {
    onPress();
  };

  useEffect(() => {
    return playing ? setButtonText(playingText) : setButtonText(notPlayingText);
  }, [playing]);

  return (
    <Pressable onPress={handlePress}>
      <Text>{buttonText}</Text>
    </Pressable>
  );
}
