import { useContext, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "../components/Themed";
import {
  Solfege as SolfegeDisplayOption,
  DisplayOptions,
} from "../components/ear-trainer/NoteInfoButton";
import Notes, { C } from "../constants/Notes";
import Scales, { Major } from "../constants/Scales";
import PlayerOptions, { Option } from "../components/ear-trainer/PlayerOptions";
import Practice from "../components/ear-trainer/Practice";
import Scale from "../models/Scale";
import {
  Default as DefaultSoundEffect,
  SoundEffects,
} from "../models/SoundPlayer";
import { FeatureContext } from "../components/Contexts/FeatureContext";

export default function TabTwoScreen() {
  const [key, setKey] = useState(C);
  const [scaleName, setScaleName] = useState(Major);
  const [displayOption, setDisplayOption] = useState(SolfegeDisplayOption);
  const [scaleMemo] = useState(new Map<string, Scale>());
  const [soundEffect, setSoundEffect] = useState(DefaultSoundEffect);

  const { enableSoundEffects } = useContext(FeatureContext);

  const scale = useMemo(() => {
    const memoKey = `${key}${scaleName}`;

    if (scaleMemo.has(memoKey)) {
      return scaleMemo.get(memoKey)!;
    }

    const newScale = new Scale(key, scaleName);
    scaleMemo.set(memoKey, newScale);
    return newScale;
  }, [key, scaleName]);

  const options = [];
  options.push({ list: Notes, hook: [key, setKey] } as Option);
  options.push({ list: Scales, hook: [scaleName, setScaleName] } as Option);
  options.push({
    list: DisplayOptions,
    hook: [displayOption, setDisplayOption],
  } as Option);

  const developerOptions = [];
  // TODO: only display option if physical keyboard is connected?
  enableSoundEffects &&
    developerOptions.push({
      list: SoundEffects,
      hook: [soundEffect, setSoundEffect],
    } as Option);

  return (
    <View style={styles.container}>
      <PlayerOptions options={developerOptions} />
      <PlayerOptions options={options} />
      <Practice
        scale={scale}
        displayOption={displayOption}
        soundEffect={soundEffect}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
