import Note from "./Note";
import Chord from "./Chord";
import Scale from "./Scale";
import { Major, Minor, Chromatic } from "../constants/Scales";

/**
 * Constructs a chord progression of chords with the following voicing
 * represented in Solfége:
 *
 * Major, Chromatic: I, IV, V, I
 *
 * I: [ Do-3, Do-4, Mi-4, Sol-4 ]
 *
 * IV: [ Fa-3, Fa-4, La-4, Do-4 ]
 *
 * V: [ Sol-3, Sol-4, Ti-4, Re-4 ]
 *
 * Minor: I-, IV-, V, I-
 *
 * I-: [ Do-3, Do-4, Me-4, Sol-4 ]
 *
 * IV-: [ Fa-3, Fa-4, Le-4, Do-4 ]
 *
 * V: [ Sol-3, Sol-4, Ti-4, Re-4 ]
 *
 * Note on minor scales: Instead of using the natural minor V- chord, we raise
 * its third to create a major triad or dominant 7 chord (V) to evoke a
 * stronger sense of resolution.
 *
 * Notation: <solfége_syllable>-<octave>
 */
export default class Cadence {
  _I: Chord;
  _IV: Chord;
  _V: Chord;

  constructor(tonic: string, scaleName: string) {
    const octave3Scale = new Scale(tonic, scaleName, 3).notes;
    const octave4Scale = new Scale(tonic, scaleName, 4).notes;
    const octave4MajorScale = new Scale(tonic, Major, 4).notes;
    const octave5Scale = new Scale(tonic, scaleName, 5).notes;

    switch (scaleName) {
      case Major:
      case Minor:
        this._I = new Chord([
          octave3Scale[0],
          octave4Scale[0],
          octave4Scale[2],
          octave4Scale[4],
        ]);
        this._IV = new Chord([
          octave3Scale[3],
          octave4Scale[3],
          octave4Scale[5],
          octave5Scale[0],
        ]);
        this._V = new Chord([
          octave3Scale[4],
          octave4Scale[4],
          octave4MajorScale[6],
          octave5Scale[1],
        ]);
        break;
      case Chromatic:
        this._I = new Chord([
          octave3Scale[0],
          octave4Scale[0],
          octave4Scale[4],
          octave4Scale[7],
        ]);
        this._IV = new Chord([
          octave3Scale[5],
          octave4Scale[5],
          octave4Scale[9],
          octave5Scale[0],
        ]);
        this._V = new Chord([
          octave3Scale[7],
          octave4Scale[7],
          octave4Scale[11],
          octave5Scale[2],
        ]);
        break;
      default:
        console.error(
          "failed to create Cadence for unknown scale: ",
          scaleName
        );
        throw new Error(
          `failed to create Cadence for unknown scale: ${scaleName}`
        );
    }
  }

  get chords() {
    return [this._I, this._IV, this._V, this._I];
  }
}
