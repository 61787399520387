import { Image, Pressable, Linking, TouchableOpacity } from "react-native";
import useColorScheme from "../hooks/useColorScheme";

const objectiveGuitarURL = "https://objectiveguitar.com";

export default function ObjectiveGuitarLogo() {
  const handlePress = async () => {
    const supported = await Linking.canOpenURL(objectiveGuitarURL);
    if (supported) {
      await Linking.openURL(objectiveGuitarURL);
    }
  };

  const theme = useColorScheme();

  return (
    <TouchableOpacity onPress={handlePress}>
      <Image
        style={{
          height: "20vh",
          width: "50vw",
        }}
        resizeMode="contain"
        source={
          theme === "dark"
            ? require("../assets/images/logos/logo-white.png")
            : require("../assets/images/logos/logo-black.png")
        }
      />
    </TouchableOpacity>
  );
}
