import { createContext, useContext } from "react";
import SoundPlayer from "../../models/SoundPlayer";

export const SoundPlayerContext = createContext<SoundPlayer | undefined>(undefined);
export const useSoundPlayerContext = () => {
    const player = useContext(SoundPlayerContext)
    // should never happen
    if(player === undefined) {
        throw new Error('SoundPlayer is undefined')
    }
    return player
}
