import * as Tone from "tone";
import { RecursivePartial } from "tone/build/esm/core/util/Interface";
import { OmniOscillatorSynthOptions } from "tone/build/esm/source/oscillator/OscillatorInterface";
import Chord from "./Chord";
import Note from "./Note";

const noteVolume = -6; // decibles
const chordVolume = -12; // decibles

/** Schedulling synth methods by as little as 50ms reduces audio glitches */
const minDelay = "+0.05"; // 50 miliseconds
const minDelayMs = 50;

export type SoundEffect = "Default" | "Fat Sawtooth";

export const Default: SoundEffect = "Default";
export const FatSawtooth: SoundEffect = "Fat Sawtooth";

export const SoundEffects = [Default, FatSawtooth];

interface PlayInfo {
  setPlaying: (playing: boolean) => void;
  delay?: number;
  minPlayingMs?: number;
}
interface PlayChordsInfo extends PlayInfo {
  chords: Chord[];
}

interface PlayNotesInfo extends PlayInfo {
  notes: Note[];
}

function getOscillator(
  soundEffect: SoundEffect
): RecursivePartial<OmniOscillatorSynthOptions> {
  switch (soundEffect) {
    case FatSawtooth:
      return { type: "fatsawtooth" };
    default:
      return {};
  }
}

export async function withDelay(fn: any, ms: number): Promise<void> {
  return new Promise((resolve) => {
    fn();
    setTimeout(() => resolve(undefined), ms);
  });
}

export async function afterDelay(fn: any, ms: number): Promise<any> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(fn()), ms);
  });
}

export default class SoundPlayer {
  noteSynth: Tone.PolySynth<Tone.Synth<Tone.SynthOptions>>;
  chordSynth: Tone.PolySynth<Tone.Synth<Tone.SynthOptions>>;
  isPlaying: boolean;
  private hasInitialized: boolean;

  constructor(soundEffect: SoundEffect = Default) {
    this.noteSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
      oscillator: getOscillator(soundEffect),
    }).toDestination();
    this.chordSynth = new Tone.PolySynth(Tone.Synth, {
      volume: chordVolume,
      oscillator: getOscillator(soundEffect),
    }).toDestination();
    this.isPlaying = false;
    this.hasInitialized = false;
  }

  restartAudioContext(soundEffect: SoundEffect = Default): void {
    this.noteSynth.releaseAll();
    this.noteSynth.disconnect();
    // this.noteSynth.dispose();

    this.chordSynth.releaseAll();
    this.chordSynth.disconnect();
    // this.chordSynth.dispose();

    this.noteSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
      oscillator: getOscillator(soundEffect),
    }).toDestination();
    this.chordSynth = new Tone.PolySynth(Tone.Synth, {
      volume: noteVolume,
      oscillator: getOscillator(soundEffect),
    }).toDestination();
  }

  private playNote(note: Note, duration: string = "8n"): void {
    this.noteSynth.triggerAttackRelease(note.value, duration, minDelay);
  }

  private playChord(chord: Chord, duration: string = "8n"): void {
    const notes = chord.toList().map((note) => note.value);
    this.chordSynth.triggerAttackRelease(notes, duration, minDelay);
  }

  triggerNoteAttack(note: Note): void {
    console.debug("playing note: ", note.value);
    this.chordSynth.triggerAttack(note.value, minDelay);
  }

  triggerNoteRelease(note: Note): void {
    console.debug("playing note: ", note.value);
    this.chordSynth.triggerRelease(note.value, minDelay);
  }

  async playNotes(notes: Note[], delay: number = 750): Promise<void> {
    await this.initializeIfNeeded();
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    const eventIds = this.scheduleEvents(notes, delay);
    Tone.Transport.start();
    await withDelay(() => {}, (delay + minDelayMs) * notes.length);
    this.clearEvents(eventIds);
  }

  async playChords(chords: Chord[], delay: number = 750): Promise<void> {
    await this.initializeIfNeeded();
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    const eventIds = this.scheduleEvents(chords, delay);
    Tone.Transport.start();
    await withDelay(() => {}, (delay + minDelayMs) * chords.length);
    this.clearEvents(eventIds);
  }

  // schedule events for cadence & query from SolfegeExercise together so that it can be cancelled when not focused
  async playChordsAndNote(
    {
      chords,
      delay: chordsDelay = 750,
      minPlayingMs: minChordPlayingMs = 200,
      setPlaying: setChordsPlaying,
    }: PlayChordsInfo,
    {
      notes,
      delay: notesDelay = 750,
      minPlayingMs: minNotePlayingMs = 200,
      setPlaying: setNotesPlaying,
    }: PlayNotesInfo
  ) {
    await this.initializeIfNeeded();
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    const chordMaxDelay = (chordsDelay / 1000) * chords.length;
    const chordEventIds = this.scheduleEvents(chords, chordsDelay);
    const noteEventIds = this.scheduleEvents(notes, notesDelay, chordMaxDelay);
    setChordsPlaying(true);
    Tone.Transport.start();
    await afterDelay(
      () => setChordsPlaying(false),
      Math.max(
        (chordsDelay + minDelayMs) * (chords.length - 1),
        minChordPlayingMs
      )
    );
    await afterDelay(() => setNotesPlaying(true), notesDelay);
    await afterDelay(
      () => setNotesPlaying(false),
      Math.max((notesDelay + minDelayMs) * (notes.length - 1), minNotePlayingMs)
    );
    this.clearEvents(chordEventIds.concat(noteEventIds));
  }

  /**
   * clears ALL sounds in the queue
   */
  cancelAllEvents() {
    Tone.Transport.cancel(0);
    this.isPlaying = false;
  }

  /**
   * clears only specific sound events so that it doesn't clean up other method call's events
   * @param eventIds sound event ids
   */
  private clearEvents(eventIds: number[]) {
    for (let eventId of eventIds) {
      Tone.Transport.clear(eventId);
    }
    this.isPlaying = false;
  }

  private async initializeIfNeeded() {
    if (!this.hasInitialized) {
      await Tone.start();
      this.hasInitialized = true;
    }
  }

  private getRelativeDelay(
    delay: number,
    multiplier: number,
    additionalDelay: number = 0
  ): string {
    return "+" + (delay * multiplier + additionalDelay).toString();
  }
  /**
   * schedule sound events using Tone.Transport
   * @param sounds list of Chord or Note to be played
   * @param delay delay in between each sound played
   * @param delayOffset delay offset, defaults to 0
   * @returns list of event ids
   */
  private scheduleEvents(
    sounds: Note[] | Chord[],
    delay: number,
    delayOffset: number = 0
  ): number[] {
    const eventIds = [];
    for (let i = 0; i < sounds.length; i++) {
      const sound = sounds[i];
      const relativeDelay = this.getRelativeDelay(delay / 1000, i, delayOffset);
      const eventId = Tone.Transport.scheduleOnce((time) => {
        if (sound instanceof Note) {
          this.playNote(sound);
        } else {
          this.playChord(sound);
        }
      }, relativeDelay);
      eventIds.push(eventId);
    }
    return eventIds;
  }
}
