import { useEffect, useMemo, useState } from "react";
import { Pressable, StyleSheet, useWindowDimensions } from "react-native";
import { Feather } from "@expo/vector-icons";
import { Text, View } from "../Themed";
import Note from "../../models/Note";
import useColorScheme from "../../hooks/useColorScheme";
import Colors from "../../constants/Colors";

export type DisplayOption = "Solfege" | "Note" | "Degree";

export const Solfege: DisplayOption = "Solfege";
export const NoteName: DisplayOption = "Note";
export const Degree: DisplayOption = "Degree";

export const DisplayOptions: DisplayOption[] = [Solfege, NoteName, Degree];

export function ButtonContainer({ children }: { children: React.ReactNode }) {
  const styles = useResponsiveStyles();
  return <View style={styles.container}>{children}</View>;
}

export default function NoteInfoButton({
  note,
  scaleSize,
  displayOption,
  correct,
  showMistakes,
  onPress,
}: {
  note: Note;
  scaleSize: number;
  displayOption?: DisplayOption;
  correct?: boolean;
  showMistakes?: boolean;
  onPress: () => void;
}) {
  const [selected, setSelected] = useState(false);
  const [centerInfo, setCenterInfo] = useState("");
  const [topLeftInfo, setTopLeftInfo] = useState("");
  const [topRightInfo, setTopRightInfo] = useState("");

  const styles = useResponsiveStyles(scaleSize);

  const setSolfegeLayout = () => {
    setCenterInfo(note.syllable);
    setTopLeftInfo(note.degree);
    setTopRightInfo(note.name);
  };

  const setNoteNameLayout = () => {
    setCenterInfo(note.name);
    setTopLeftInfo(note.syllable);
    setTopRightInfo(note.degree);
  };

  const setDegreeLayout = () => {
    setCenterInfo(note.degree);
    setTopLeftInfo(note.name);
    setTopRightInfo(note.syllable);
  };

  useEffect(() => {
    switch (displayOption) {
      case Solfege:
        setSolfegeLayout();
        break;
      case NoteName:
        setNoteNameLayout();
        break;
      case Degree:
        setDegreeLayout();
        break;
      default:
        setSolfegeLayout();
    }
  }, [note, displayOption]);

  const selectedStyle = useMemo(
    () =>
      selected &&
      (correct
        ? styles.correct
        : showMistakes
        ? styles.mistake
        : styles.selected),
    [selected, correct, showMistakes]
  );

  const handlePress = () => {
    setSelected(true);
    setTimeout(() => setSelected(false), 300); // 300ms is how long a single note plays for: 8th (250ms) + 50sm
    onPress();
  };

  return (
    <Pressable
      onPress={() => handlePress()}
      style={[styles.button, selectedStyle]}
    >
      {selected && correct ? (
        <Feather name={"check"} style={selectedStyle} />
      ) : selected && !correct && showMistakes ? (
        <Feather name={"x"} style={selectedStyle} />
      ) : (
        <>
          <Text style={[styles.center, selectedStyle]}>{centerInfo}</Text>
          <Text style={[styles.topLeft, selectedStyle]}>{topLeftInfo}</Text>
          <Text style={[styles.topRight, selectedStyle]}>{topRightInfo}</Text>
        </>
      )}
    </Pressable>
  );
}

const useResponsiveStyles = (scaleSize: number = 0) => {
  const { width } = useWindowDimensions();
  const theme = useColorScheme();

  const vw = width / 100;

  return StyleSheet.create({
    container: {
      paddingVertical: 5 * vw,
      paddingHorizontal: 1 * vw,
      display: "flex",
      flexDirection: "row",
    },
    button: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      margin: (95 * vw) / (2 * scaleSize ** 2),

      // width: "11vw", // fits 8 buttons horizontally
      // height: "11vw", // square to width

      width: (95 * vw) / (scaleSize + 1),
      height: (95 * vw) / (scaleSize + 1),

      borderWidth: 1,
      borderColor: Colors[theme].text,
      borderRadius: width * 0.01,
    },
    selected: {
      backgroundColor: Colors[theme].text,
      color: Colors[theme].background,
    },
    correct: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: Colors[theme].success,
      color: Colors[theme].background,
      borderColor: Colors[theme].success,
      fontSize: 8 * vw,
    },
    mistake: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: Colors[theme].mistake,
      color: Colors[theme].background,
      borderColor: Colors[theme].mistake,
      fontSize: 8 * vw,
    },
    center: {
      position: "absolute",
      top: (28 * vw) / scaleSize,
      fontSize: (32 * vw) / scaleSize,
    },
    topLeft: {
      position: "absolute",
      top: (6 * vw) / scaleSize,
      left: (10 * vw) / scaleSize,
      fontSize: (20 * vw) / scaleSize,
    },
    topRight: {
      position: "absolute",
      top: (6 * vw) / scaleSize,
      right: (10 * vw) / scaleSize,
      fontSize: (20 * vw) / scaleSize,
    },
  });
};
