import Note from "./Note";
import Notes, { NoteAlternatives, NoteLetters } from "../constants/Notes";
import {
  ScaleName,
  Major,
  MajorScale,
  Minor,
  MinorScale,
  Blues,
  BluesScale,
  MajorPentatonic,
  MajorPentatonicScale,
  Chromatic,
  ChromaticScale,
} from "../constants/Scales";

const syllables = [
  "Do",
  "Ra",
  "Re",
  "Me",
  "Mi",
  "Fa",
  "Se",
  "Sol",
  "Le",
  "La",
  "Te",
  "Ti",
];

const degrees = [
  "1",
  "♭2",
  "2",
  "♭3",
  "3",
  "4",
  "♭5",
  "5",
  "♭6",
  "6",
  "♭7",
  "7",
];

export function getNextNoteLetter(noteLetter: string): string {
  if (!NoteLetters.includes(noteLetter)) {
    throw new Error("unxepected note letter");
  }

  const i = NoteLetters.indexOf(noteLetter);

  if (i === NoteLetters.length - 1) {
    return NoteLetters[0];
  } else {
    return NoteLetters[i + 1];
  }
}

function getAlternativeNoteName(noteName: string, letter: string) {
  if (NoteAlternatives.has(noteName)) {
    const alternative = NoteAlternatives.get(noteName)!;

    if (alternative.replaceAll(/[♭|♯]/g, "") === letter) {
      return alternative;
    }
  }

  throw `failed to find alternative name for "${noteName}"`;
}

/**
 * When naming the notes in a given scale, letters should not be repeated.
 *
 * For example, the Em scale could be written as "E G♭ G A B C D E" but
 * replacing the "G♭" with "F♯" is preferred
 */
function generateNotes(tonic: string, octave: number, intervals: number[]) {
  const root = Notes.indexOf(tonic);
  const noteNames = [...Notes, ...Notes].slice(root, root + 12); // shift to start from tonic
  const octaves = [
    ...new Array(Notes.length).fill(octave),
    ...new Array(Notes.length).fill(octave + 1),
  ].slice(root, root + 12); // shift to start from tonic

  let semitone = 0;
  const semitones = intervals.map((interval) => (semitone += interval));

  const generated: Note[] = [];
  generated.push(new Note(noteNames[0], syllables[0], degrees[0], octaves[0]));

  semitones.slice(1).map((i) => {
    let noteName = noteNames[i];
    let noteLetter = noteName.replaceAll(/[♭|♯]/g, "");
    console.debug(noteName, "is the default note for semitone", i);

    const nextLetter = getNextNoteLetter(
      generated.at(-1)!.name.replaceAll(/[♭|♯]/g, "")
    );

    if (nextLetter !== noteLetter) {
      noteName = getAlternativeNoteName(noteName, nextLetter);
      console.debug(
        "letter",
        noteLetter,
        "has already been used and will be replaced with",
        noteName
      );

      if (noteLetter === "B" && nextLetter === "C") {
        console.debug("octave bump");
        octaves[i]++;
      }

      if (noteLetter === "C" && nextLetter === "B") {
        console.debug("octave drop");
        octaves[i]--;
      }
    }

    generated.push(new Note(noteName, syllables[i], degrees[i], octaves[i]));
  });

  generated.push(new Note(noteNames[0], "Do", "1", octave + 1));
  console.table(generated);

  return generated;
}

/**
 * Generates a scale of notes for a given octave from a tonic using the interval (semitones) to each syllable.
 * Each note contains a Solfége syllable, note name, degree, and octave.
 *
 * If no octave is given, defaults to "one-lined" (i.e. C4 - B4)
 */
export default class Scale {
  readonly notes: Note[];

  _log(tonic: string, scaleName: ScaleName, octave: number) {
    console.debug(
      "constructing new Scale in key of",
      Notes[Notes.indexOf(tonic)],
      scaleName,
      "at octave",
      octave
    );
  }

  constructor(tonic: string, scaleName: ScaleName, octave: number = 4) {
    switch (scaleName) {
      case Major:
        this._log(tonic, scaleName, octave);
        this.notes = generateNotes(tonic, octave, MajorScale);
        break;
      case Minor:
        this._log(tonic, scaleName, octave);
        this.notes = generateNotes(tonic, octave, MinorScale);
        break;
      case Chromatic:
        this._log(tonic, scaleName, octave);
        this.notes = generateNotes(tonic, octave, ChromaticScale);
        break;
      case Blues:
        this._log(tonic, scaleName, octave);
        this.notes = generateNotes(tonic, octave, BluesScale);
        break;
      case MajorPentatonic:
        this._log(tonic, scaleName, octave);
        this.notes = generateNotes(tonic, octave, MajorPentatonicScale);
        break;
      default:
        this._log(tonic, "major", octave);
        this.notes = generateNotes(tonic, octave, MajorScale);
    }
  }
}
