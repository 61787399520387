/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  NavigationContainer,
  DarkTheme,
  DefaultTheme,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { ColorSchemeName } from "react-native";

import InfoButton from "../components/InfoButton";
import Colors from "../constants/Colors";
import useColorScheme from "../hooks/useColorScheme";
import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import TabOneScreen from "../screens/TabOneScreen";
import TabTwoScreen from "../screens/TabTwoScreen";
import {
  RootStackParamList,
  RootTabParamList,
  RootTabScreenProps,
} from "../types";
import LinkingConfiguration from "./LinkingConfiguration";

const RootRouteName = "Root";
const NotFoundRouteName = "NotFound";
const TabOneRouteName = "TabOne";
const TabTwoRouteName = "TabTwo";
const InfoRouteName = "Info";

export default function Navigation({
  colorScheme,
}: {
  colorScheme: ColorSchemeName;
}) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name={RootRouteName}
        component={BottomTabNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name={NotFoundRouteName}
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
      <Stack.Group screenOptions={{ presentation: "modal" }}>
        <Stack.Screen name={InfoRouteName} component={ModalScreen} />
      </Stack.Group>
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();

  return (
    <BottomTab.Navigator
      initialRouteName={TabOneRouteName}
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tabIconSelected,
      }}
    >
      <BottomTab.Screen
        name={TabOneRouteName}
        component={TabOneScreen}
        options={({
          navigation,
        }: RootTabScreenProps<typeof TabOneRouteName>) => ({
          title: "TRAIN",
          tabBarIcon: ({ color }) => (
            <TabBarIconI name="repeat" color={color} />
          ),
          headerRight: () => (
            <InfoButton onPress={() => navigation.navigate(InfoRouteName)} />
          ),
        })}
      />
      <BottomTab.Screen
        name={TabTwoRouteName}
        component={TabTwoScreen}
        options={({
          navigation,
        }: RootTabScreenProps<typeof TabTwoRouteName>) => ({
          title: "PLAY",
          tabBarIcon: ({ color }) => (
            <TabBarIconMCI name="piano" color={color} />
          ),
          headerRight: () => (
            <InfoButton onPress={() => navigation.navigate(InfoRouteName)} />
          ),
        })}
      />
    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIconI(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={36} style={{ marginBottom: -3 }} {...props} />;
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIconMCI(props: {
  name: React.ComponentProps<typeof MaterialCommunityIcons>["name"];
  color: string;
}) {
  return (
    <MaterialCommunityIcons size={36} style={{ marginBottom: -3 }} {...props} />
  );
}
