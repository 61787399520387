import Note from "./Note";

export default class Chord {
  _notes: Note[] = [];

  constructor(notes: Note[]) {
    // TODO: should we fail to construct if there are less than three notes?
    this._notes = notes;
  }

  toList() {
    return this._notes;
  }
}
