import { StyleSheet, View } from "react-native";
import { Dispatch, SetStateAction } from "react";
import { Picker } from "@react-native-picker/picker";
import { useThemeColor } from "../Themed";
import Colors from "../../constants/Colors";
import useColorScheme from "../../hooks/useColorScheme";

export type Option = {
  list: string[];
  hook: [string, Dispatch<SetStateAction<string>>];
};

export default function PlayerOptions({ options }: { options: Option[] }) {
  const themedStyles = styles();

  return (
    <View style={themedStyles.container}>
      {options.map((option, i) => {
        const [current, setter] = option?.hook;
        return (
          <Picker
            style={themedStyles.picker}
            key={`option-list-${i}`}
            selectedValue={current}
            onValueChange={setter}
          >
            {option.list?.map((value: string, j: number) => {
              return (
                <Picker.Item
                  key={`option-value-${j}`}
                  label={value}
                  value={value}
                />
              );
            })}
          </Picker>
        );
      })}
    </View>
  );
}

const styles = () => {
  const theme = useColorScheme();

  return StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "85%",
      justifyContent: "space-evenly",
      padding: "5%",
    },
    picker: {
      margin: "2vw",
      border: "none",
      borderRadius: 100,
      paddingVertical: "6px",
      paddingHorizontal: "10px",

      color: Colors[theme].tint,
      backgroundColor: Colors[theme].pickerBackground,

      fontSize: 22,
    },
  });
};
