const accoustic = "#a15e05";
const success1 = "#1e8a44";
const success2 = "#4ea646";
const success3 = "#e0eccf";
const error1 = "#da212c";
const error2 = "#e86363";
const error3 = "#fff5e4";
const warn1 = "#fbad26";
const warn2 = "#fec157";
const warn3 = "#fff5e4";

const basicBlackAndWhite = {
  light: {
    text: "#000",
    background: "#eee",
    tint: accoustic,
    tabIconDefault: "#333",
    tabIconSelected: accoustic,
    pickerBackground: "#ddd",
    codeHighlightBackground: "#0001",
    success: success1,
    mistake: warn2,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: accoustic,
    tabIconDefault: "#ccc",
    tabIconSelected: accoustic,
    pickerBackground: "#111",
    codeHighlightBackground: "#fff1",
    success: success1,
    mistake: warn1,
  },
};

export default basicBlackAndWhite;
