export type ScaleName = string;

export const Major: ScaleName = "Major";
export const Minor: ScaleName = "Minor";
export const Chromatic: ScaleName = "Chromatic";
export const MajorPentatonic: ScaleName = "Major Pentatonic";
export const Blues: ScaleName = "Blues";

export default [Major, Minor /* Chromatic */ /* MajorPentatonic, Blues */];

/*
Scales are represented as a ordered series of intervals between semitones.
*/
export const MajorScale = [0, 2, 2, 1, 2, 2, 2];
export const MinorScale = [0, 2, 1, 2, 2, 1, 2];
export const ChromaticScale = [0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
export const MajorPentatonicScale = [0, 2, 2, 3, 2];
export const BluesScale = [0, 3, 2, 1, 1, 3];
