export const C = "C";
export const Db = "D♭";
export const D = "D";
export const Eb = "E♭";
export const E = "E";
export const F = "F";
export const Gb = "G♭";
export const G = "G";
export const Ab = "A♭";
export const A = "A";
export const Bb = "B♭";
export const B = "B";

export const Notes = [C, Db, D, Eb, E, F, Gb, G, Ab, A, Bb, B];

export const NoteAlternatives = new Map<string, string>([
  [Db, "C♯"],
  [D, "E♭♭"],
  [Eb, "D♯"],
  [E, "F♭"],
  [Gb, "F♯"],
  [Ab, "G♯"],
  [A, "B♭♭"],
  [Bb, "A♯"],
  [B, "C♭"],
]);

export const NoteLetters = ["C", "D", "E", "F", "G", "A", "B"];

export default Notes;
