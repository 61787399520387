/**
 * A note is represented by four values:
 * - `name`: the basic note name e.g. "C", "D", etc.
 * - `syllable`: the note's assigned Solfége syllable with respect to the scale
 * - `degree`: the position of the note in the scale
 * - `octave`: the note's octave
 *
 * A note's `value` is a formatted serliazation of the note's name and octave.
 * The flat accidental symbol "♭" is replaced with the letter "b".
 * The sharp accidental symbol "♭" is replaced with the symbol "#".
 * For example, a note constructed with `let note = new Note("C♭", "Do", "1", 4)`, `note.value` returns "Cb4"
 */
export default class Note {
  name: string;
  readonly syllable: string;
  readonly degree: string;
  readonly octave: number;

  constructor(
    name: string,
    syllable: string,
    degree: string,
    octave: number = 4
  ) {
    this.name = name;
    this.syllable = syllable;
    this.degree = degree;
    this.octave = octave;
  }

  get value(): string {
    return (
      this.name.replaceAll(/♭/g, "b").replaceAll(/♯/g, "#") +
      String(this.octave)
    );
  }
}
