import { useCallback, useContext, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "../Themed";
import { Default, afterDelay } from "../../models/SoundPlayer";
import Cadence from "../../models/Cadence";
import Note from "../../models/Note";
import Scale from "../../models/Scale";
import NoteInfoButton, {
  ButtonContainer,
  DisplayOption,
} from "./NoteInfoButton";
import PlaySoundButton from "../PlaySoundButton";
import { useSoundPlayerContext } from "../Contexts/SoundPlayerContext";
import useSoundFocus from "../../hooks/useSoundFocus";

/**
 *
 * @param notes - The sample set.
 * @returns A random note.
 */
function pickRandomNote(notes: Note[]): Note {
  return notes[Math.floor(Math.random() * notes.length)];
}

/**
 * Identifies the degree of the note in the scale and the steps towards the closer tonic.
 * @param note - The note to resolve in the scale.
 * @param notes - The list of notes in the scale sorted from tonic to tonic (octave).
 * @returns A list of of notes.
 */
function inferResolution(note: Note, notes: Note[]): Note[] {
  const degree = notes.indexOf(note) + 1;
  if (degree > notes.length / 2) {
    return notes.slice(degree - 1);
  }
  return notes.slice(0, degree).reverse();
}

export default function SolfegeExercise({
  scale,
  cadence,
  displayOption: buttonOption,
}: {
  scale: Scale;
  cadence: Cadence;
  displayOption: DisplayOption;
}) {
  const player = useSoundPlayerContext();

  const notes = Array.from(scale.notes);

  const [query, setQuery] = useState(() => pickRandomNote(notes));
  const [playingCadence, setPlayingCadence] = useState(false);
  const [playingQuery, setPlayingQuery] = useState(false);
  const resolution = inferResolution(query, notes);
  useSoundFocus(Default);

  useEffect(() => {
    /**
     * Though not strictly a side effect, using useEffect hook to prevent
     * unexpectedly calling startExercise during other re-renders.
     */
    startExercise(cadence, query);
  }, [query]);

  /**
   * Uses 50ms delays to animations and state changes to
   * be in sync with SoudPlayer's audio which is delayed by 50ms.
   * @param cadence
   */
  const playQuery = useCallback(
    async (query: Note) => {
      if (!player.isPlaying) {
        await afterDelay(() => setPlayingQuery(true), 50);
        await player.playNotes([query], 0);
        await afterDelay(() => setPlayingQuery(false), 50);
      }
    },
    [query]
  );

  /**
   * Play cadence and then the query tone.
   * @param cadence - A chord progression that establishes the key.
   * @param query - The tone the user needs to guess.
   */
  const startExercise = useCallback(
    async (cadence: Cadence, query: Note): Promise<void> => {
      await player.playChordsAndNote(
        {
          chords: cadence.chords,
          setPlaying: setPlayingCadence,
        },
        {
          notes: [query],
          setPlaying: setPlayingQuery,
        }
      );
    },
    [cadence, query]
  );

  /**
   * If the user guesses the query note, play the resolution of that note then resume the exericse with a random note.
   * Otherwise, play the note of the wrong guess.
   * @param guess - The user's guess.
   */
  const handleGuess = async (guess: Note): Promise<void> => {
    if (guess === query) {
      console.debug("correct!");
      await player.playNotes(resolution, 750 / 2);
      await afterDelay(() => setQuery(pickRandomNote(notes)), 1000);
    } else {
      console.debug("wrong!");
      await player.playNotes([guess], 0);
    }
  };

  return (
    <>
      <View style={styles.container}>
        <PlaySoundButton
          label="CADENCE"
          duration={4500}
          playing={playingCadence}
          onPress={() => startExercise(cadence, query)}
        />
        <PlaySoundButton
          label="NOTE"
          duration={750}
          playing={playingQuery}
          onPress={() => playQuery(query)}
        />
      </View>
      <ButtonContainer>
        {notes.map((note, i) => {
          return (
            /* Generate a button for each note in scale */
            <NoteInfoButton
              key={`button-${i}`}
              note={note}
              scaleSize={notes.length}
              displayOption={buttonOption}
              correct={query === note}
              showMistakes={true}
              onPress={() => handleGuess(note)}
            />
          );
        })}
      </ButtonContainer>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "85%",
  },
});
