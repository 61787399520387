import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  disableAllOptionalFeatures,
  enableAllOptionalFeatures,
  FeatureContext,
} from "./components/Contexts/FeatureContext";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import { useEffect, useState } from "react";
import unmute from "./models/unmute";
import { SoundPlayerContext } from "./components/Contexts/SoundPlayerContext";
import SoundPlayer from "./models/SoundPlayer";

interface UnmuteHandler {
  dispose: () => void;
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const [unmuteHandler, setUnmuteHandler] = useState<UnmuteHandler>();
  const [player, setPlayer] = useState<SoundPlayer>();

  useEffect(() => {
    document.addEventListener(
      "pointerdown",
      () => {
        const context = new (window.AudioContext ||
          window.webkitAudioContext)();
        setPlayer(new SoundPlayer());
        const handler = unmute(context, false, false);
        setUnmuteHandler(handler);
      },
      { once: true }
    );
  }, []);

  useEffect(() => {
    return () => unmuteHandler?.dispose();
  }, [unmuteHandler]);

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <FeatureContext.Provider
          value={
            __DEV__ ? enableAllOptionalFeatures : disableAllOptionalFeatures
          }
        >
          <SoundPlayerContext.Provider value={player}>
            <Navigation colorScheme={colorScheme} />
            <StatusBar />
          </SoundPlayerContext.Provider>
        </FeatureContext.Provider>
      </SafeAreaProvider>
    );
  }
}
